// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --name-text-font-size: var(--font-size-regular);
  --name-text-font-weight: var(--font-weight-medium);
  --name-text-color: var(--ion-color-light-contrast);
  --name-text-align: flex-start;
  --country-text-font-size: var(--font-size-regular);
  --country-text-font-weight: var(--font-weight-regular);
  --country-text-color: var(--mi-color-text-lite);
  --country-text-align: left;
}

.larger-text .full-name .full-name-text {
  font-size: var(--font-size-large) !important;
}

.user-full-name .full-name {
  display: grid;
  grid-template-rows: min-content;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: var(--name-text-align);
  align-items: center;
  max-width: 100%;
}
.user-full-name .full-name .full-name-text {
  font-size: var(--name-text-font-size);
  font-weight: var(--name-text-font-weight);
  color: var(--name-text-color);
  margin: 0;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-full-name .full-name .verified-badge {
  margin-left: 3px;
}
.user-full-name .full-name .crown-user {
  margin-left: 3px;
}
.user-full-name .full-name .verified-badge-container {
  display: flex;
  height: 100%;
  align-items: center;
}
.user-full-name .full-name .crown-badge-container {
  display: flex;
  height: 100%;
  align-items: center;
}
.user-full-name .full-name .orc-id-icon-container {
  height: 100%;
  display: flex;
  align-items: center;
}
.user-full-name .full-name .orc-id-icon-container .orc-id-icon {
  --icon-size: 15px;
  margin-left: 3px;
  font-size: unset;
}
.user-full-name .full-name .additional-text-to-name {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-regular);
  color: var(--mi-secondary-color-2);
}
.user-full-name .moderator-icon {
  color: var(--ion-color-secondary);
  display: inline-block;
}
.user-full-name .country,
.user-full-name .organisation {
  font-size: var(--country-text-font-size);
  font-weight: var(--country-text-font-weight);
  color: var(--country-text-color);
  text-align: var(--country-text-align);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-full-name app-verified-badge,
.user-full-name app-crown-badge {
  height: 100%;
  display: flex;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
