// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-container {
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}
.dialog-container .icon-container {
  height: 100px;
  width: 100px;
  background-color: var(--mi-purple-color-13);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-container .icon-container app-icon {
  --icon-size: 55px;
}
.dialog-container .title {
  font-size: var(--font-size-extra-large);
  font-weight: var(--font-weight-semi-bold);
  color: var(--mi-black-color);
}
.dialog-container .custom-ordered-list {
  list-style: decimal;
}
.dialog-container .custom-ordered-list li {
  font-size: var(--font-size-large);
  margin-bottom: 8px;
}
.dialog-container .custom-ordered-list li::marker {
  font-weight: var(--font-weight-semi-bold);
}
.dialog-container ion-button {
  --background: var(--mi-primary-color);
  --border-radius: 4px;
  color: var(--ion-color-primary-contrast);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  margin: 5px 0 5px 0;
  display: flex;
  text-transform: none;
  width: 85%;
}
.dialog-container .close-button {
  font-size: var(--font-size-regular);
  color: var(--mi-black-color);
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
