import { Component } from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-to-review-edit-case-info-dialog',
  templateUrl: './to-review-edit-case-info-dialog.component.html',
  styleUrls: ['./to-review-edit-case-info-dialog.component.scss'],
})
export class ToReviewEditCaseInfoDialogComponent {

  constructor(private modalController: ModalController) { }

  onGotIt(): void {
    this.modalController.dismiss(null, 'got-it');
  }

  onCloseAndDoNotShowAgain(): void {
    this.modalController.dismiss(null, 'close-and-do-not-show-again');
  }

}
