import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ImageAttachment } from 'src/app/services/attachments.model';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { Message } from 'src/app/services/yeti-protocol/message';
import { MessageCardBase } from '../message-card-base';
import { MessagePressedEvent } from '../messenger-list.model';
import { ImageGalleryService } from 'src/app/modules/file-select/services/image-gallery.service';
import { GalleryConfig } from 'src/app/modules/file-select/services/image-gallery.model';
import { FileSelectScope } from 'src/app/modules/file-select/services/file-select.service';

@Component({
  selector: 'app-message-card-image',
  templateUrl: './message-card-image.component.html',
  styleUrls: ['./message-card-image.component.scss'],
})
export class MessageCardImageComponent extends MessageCardBase {
  @Input() message: Message;
  @Input() userId: string;

  @Output() showCommands: EventEmitter<MessagePressedEvent> = new EventEmitter<MessagePressedEvent>()

  constructor(
    responsiveUtilsService: ResponsiveUtilsService,
    private imageGalleryService: ImageGalleryService) {
    super(responsiveUtilsService);
  }

  @HostBinding('class') get viewMode(): string {
    if (this.isOwnMessage) {
      return 'chat-initiator';
    }
    return '';
  }

  openGalleryModal(): void {

    const galleryConfig: GalleryConfig = {
      images: this.imageAttachments.map(image => {
        return {
          ...image,
          previewUrl: (image as any)?.url,
          fullUrl: (image as any)?.fullImageUrl
        }
      }),
      scope: FileSelectScope.CHAT,
      imageGalleryStrategy: null
    };

    this.imageGalleryService.openImageGallery(galleryConfig);
  }

  get hasAttachments(): boolean {
    return this.imageAttachments.length > 0;
  }

  get imageAttachments(): Array<ImageAttachment> {
    return this.message.attachments.filter(attachment => {
      return attachment.mimeType?.indexOf('image') > -1;
    });
  }
}
