import { Component, Input, OnInit } from '@angular/core';
import { GroupsService } from '../../services/groups/groups.service';
import { ToastMode, ToastService } from '../../services/toast.service';
import {
  GroupByIdResponse,
  GroupByIdSuccessResponse,
  GroupMember
} from '../../services/yeti-protocol/chatter-api'
import { ModalController } from '@ionic/angular';
import { Group } from '../../services/groups/group.model';
import { GroupInvitationsAoSearchComponent } from '../group-invitations-ao-search/group-invitations-ao-search.component';
import { AppTranslationService } from '../../services/app-translation.service';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import { SeeAllPendingRequestsPendingInvitationsComponent }
  from '../all-pending-requests/see-all-pending-requests-pending-invitations.component';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { AppNavController } from 'src/app/services/app-nav-controller.service';

@Component({
  selector: 'app-group-invitations',
  templateUrl: './group-invitations.component.html',
  styleUrls: ['./group-invitations.component.scss'],
})
export class GroupInvitationsComponent implements OnInit {
  @Input() groupId: string;
  @Input() groupVisibility: string;
  @Input() userProfile: UserProfile;
  pendingInvitations: Array<GroupMember>;
  showEmailError: boolean;
  emailSearch: string;
  isPrivateGroup: boolean;

  get email(): any {
    return this.emailForm.get('email');
  }

  get emailCondition(): any {
    return this.email.hasError(this.errorMessages.email[0].type) && (this.email.dirty || this.email.touched)
  }

  public errorMessages = {
    email: [
      {
        type: 'pattern', message: 'Please enter a valid email address'
      }
    ]
  };

  emailForm = this.formBuilder.group({
    email: ['',
      // eslint-disable-next-line max-len
      [Validators.pattern('[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?')]]
  });

  group: Group;

  constructor(
    private toast: ToastService,
    private groupsService: GroupsService,
    private modalController: ModalController,
    private dialogs: DialogsUIService,
    private appTranslationService: AppTranslationService,
    private formBuilder: FormBuilder,
    private appNavController: AppNavController,
  ) {
  }

  ngOnInit(): void {
    if (this.groupId) {
      this.getMemberRequests(this.groupId, 0, 20);
    }
    if (this.groupsService) {
      this.groupsService.getGroupById(this.groupId).then((groupResponse: GroupByIdResponse) => {
        this.group = ((groupResponse as GroupByIdSuccessResponse).result);
      }).catch(async (err) => {
        this.toast.showWithMessage(err, 'app.common.error-default', ToastMode.ERROR);
      });
    }
    if (this.groupVisibility) {
      this.isPrivateGroup = this.groupVisibility === 'Unlisted';
    }
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  checkGroupOwner(): boolean {
    if (this.userProfile && this.group) {
      return (this.group.owner.userId === this.userProfile.id) ||
        (this.group.moderators.some(moderator => moderator.userId === this.userProfile.id));
    } else {
      return false;
    }
  }

  async inviteWithEmail(): Promise<void> {
    // eslint-disable-next-line max-len
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (re.test(String(this.emailSearch).toLowerCase())) {
      this.groupsService.inviteToGroup(this.groupId, this.emailSearch).then(async (response) => {
        this.emailSearch = '';
        if (response && response.success) {
          this.toast.show('app.groups.GroupsInvitations.StartPage.invited-via-email',
            'app.common.success', ToastMode.SUCCESS);
          this.getMemberRequests(this.groupId, 0, 20);
        } else {
          const msg = JSON.stringify(response.error.info).match(/:(?<message>.*?)\\/).groups.message;
          this.toast.showWithMessage(msg, 'app.common.error-default', ToastMode.ERROR);
        }
      })
        .catch(err => {
          if (err?.error?.error?.message?.errfor?.message) {
            this.dialogs.showErrorDialog(err?.error?.error?.message?.errfor?.message);
          }
        })
    }
  }

  getMemberRequests(groupId: string, start: number, count: number): void {
    this.groupsService.getMemberRequests(groupId, start, count, 'pending-invitation').then(response => {
      if (response && response.result.length) {
        this.pendingInvitations = response.result;
      }
    })
      .catch(async (err) => {
        this.dialogs.showErrorDialog(
          await this.appTranslationService.get('app.groups.GroupsInvitations.StartPage.could-not-get-member-request'
            + err.message));
      })
  }

  sendReminder(member: GroupMember): void {
    this.groupsService.sendReminderForGroupInvitation(this.groupId, member.username)
      .then(() => {
        const groupMemberIndex = this.pendingInvitations.findIndex(invitation => member.userId === invitation.userId);
        if (groupMemberIndex !== -1) {
          this.pendingInvitations[groupMemberIndex].isSendReminderAvailable = false;
        }
        this.toast.show(
          'app.groups.GroupsInvitations.StartPage.reminder-sent-successfully',
          'app.common.success',
          ToastMode.SUCCESS
        );
      }).catch(_err => {
        this.toast.show(
          'app.groups.GroupsInvitations.StartPage.error-occurred-while-sending-reminder',
          'app.common.error-default',
          ToastMode.ERROR
        );
      })
  }

  updateGroupMember(groupId: string, member: GroupMember, role: string, status?: string): void {
    this.groupsService.updateGroupMember(member.userId, role, groupId, status,).then(response => {
      if (response) {
        this.getMemberRequests(groupId, 0, 15);
      }
    })
      .catch(async () => {
        this.dialogs.showErrorDialog(await this.appTranslationService.get('app.common.something-went-wrong'));
      })
  }

  async goToAoSearch(): Promise<void> {
    const groupInvitations = await this.modalController.create({
      component: GroupInvitationsAoSearchComponent,
      cssClass: 'group-invitations-ao-search-dialog',
      componentProps: {
        groupId: this.groupId
      },
    });
    groupInvitations.present();
    groupInvitations.onDidDismiss().then(() => {
      this.getMemberRequests(this.groupId, 0, 20);
    });
  }

  async showAllPendingInvitations(): Promise<void> {
    const pendingInvitations = await this.modalController.create({
      component: SeeAllPendingRequestsPendingInvitationsComponent,
      cssClass: 'all-pending-requests-dialog',
      componentProps: {
        groupId: this.groupId,
        status: 'pending-invitation'
      }
    });
    pendingInvitations.present();
  }

  openPublicProfile(userId: string): void {
    this.modalController.dismiss();
    this.appNavController.openPublicUserProfile(userId);
  }
}
