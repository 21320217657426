import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FileSelectComponent } from 'src/app/modules/file-select/components/file-select/file-select.component';
import { FileType, SelectedFile } from 'src/app/modules/file-select/file-select.model';
import { FileSelectScope } from 'src/app/modules/file-select/services/file-select.service';
import { AttachmentMimeType, DocumentAttachment } from 'src/app/services/attachments.model';
import { getFileName } from '../../services/utils/files-edit-and-upload-utils';
import { ModalController } from '@ionic/angular';
import { UploadFile } from '../../services/yeti-protocol/upload';
import { UploadService } from '../../services/upload.service';
import { DialogsUIService } from '../../services/dialogs/dialogs.ui.service';
import { ConfirmDialogData } from '../../services/dialogs/dialogs.ui.interface';
import { ActionTracked, TrackingRequest, UploadMediaTrackingParam } from '../../services/yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from '../../services/tracking/tracking.model';

@Component({
  selector: 'app-upload-ppt-flow-dialog',
  templateUrl: './upload-ppt-flow-dialog.component.html',
  styleUrls: ['./upload-ppt-flow-dialog.component.scss'],
})
export class UploadPptFlowDialogComponent implements OnInit {

  @ViewChild('fileSelect') fileSelect: FileSelectComponent;

  attachedFile: DocumentAttachment;
  pptUploadProgress: number;
  preparingPptUpload: boolean;

  protected readonly MAX_FILE_SIZE_MB = 200;
  protected readonly FileSelectScope = FileSelectScope;
  protected readonly FileType = FileType;
  protected readonly AttachmentMimeType = AttachmentMimeType;

  constructor(
    private modalController: ModalController,
    private uploadService: UploadService,
    private dialogs: DialogsUIService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) {
  }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.trackActionClicks('ppt2CaseUploadCanceled', 'case', 'caseCreateSelector');
    this.modalController.dismiss(null, 'cancel');
  }

  addFiles(): void {
    this.fileSelect.selectFile();
  }

  filesSelected(files: Array<SelectedFile>): void {
    const mappedFile = files.map(file => {
      return {
        _id: '',
        fileName: getFileName(file),
        url: '',
        mimeType: file.file.type,
        contentLength: file.file.size,
        file: file.file,
      } as DocumentAttachment
    }).pop();

    if (this.isFileLargerThanFileLimit(mappedFile.contentLength)) {
      this.trackFileEvent(ActionTracked.uploaded, mappedFile.fileName, mappedFile.mimeType, 'File too large');
      return;
    }

    if (!this.isFileProperMimeType(mappedFile.mimeType)) {
      this.trackFileEvent(ActionTracked.uploaded, mappedFile.fileName, mappedFile.mimeType, 'File type not supported');
      return;
    }

    this.trackFileEvent(ActionTracked.fileAttached, 'ppt2CaseFileAttached', 'case');
    this.attachedFile = mappedFile;
  }

  isFileProperMimeType(fileType: string): boolean {
    const allowedMimeTypes = AttachmentMimeType.PPT.split(',');
    const isAllowed = allowedMimeTypes.includes(fileType);

    if (!isAllowed) {
      this.showConfirmDialog(
        'app.dialogs.UploadPptFlowDialog.unsupportedTitle',
        'app.dialogs.UploadPptFlowDialog.unsupportedText',
        'app.dialogs.UploadPptFlowDialog.unsupportedButton'
      )
    }

    return isAllowed;
  }

  isFileLargerThanFileLimit(fileSizeInBytes: number): boolean {
    const contentLengthInKB = Math.floor(fileSizeInBytes / 1000);
    const contentLengthInMB = Math.floor(contentLengthInKB / 1000);
    const isLarger = contentLengthInMB > this.MAX_FILE_SIZE_MB;

    if (isLarger) {
      this.showConfirmDialog(
        'app.dialogs.UploadPptFlowDialog.fileTooLargeTitle',
        'app.dialogs.UploadPptFlowDialog.fileTooLargeText',
        'app.dialogs.UploadPptFlowDialog.fileTooLargeButton'
      )
    }

    return isLarger;
  }

  async showConfirmDialog(
    titleTranslationKey: string,
    messageTranslationKey: string,
    cancelButtonKey: string,
    okButtonKey?: string
  ): Promise<{ data: { actionKey: string } }> {
    const confirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: titleTranslationKey
        },
        message: {
          translationKey: messageTranslationKey
        },
        actions: [
          {
            key: 'cancel',
            label: {
              translationKey: cancelButtonKey
            },
            className: okButtonKey ? 'secondary' : 'primary'
          },
        ]
      }
    }

    if (okButtonKey) {
      confirmDialogData.componentProps.actions.push({
        key: 'ok',
        label: {
          translationKey: okButtonKey
        },
        className: 'primary'
      });
    }

    const confirmModal = await this.dialogs.createConfirmDialog(confirmDialogData);
    await confirmModal.present();
    return await confirmModal.onDidDismiss() as Promise<{ data: { actionKey: string } }>;
  }

  removeAttachedDocument(): void {
    this.showConfirmDialog(
      'app.dialogs.UploadPptFlowDialog.removeTitle',
      'app.dialogs.UploadPptFlowDialog.removeText',
      'app.common.cancel',
      'app.dialogs.UploadPptFlowDialog.removeButton'
    ).then(res => {
      if (res.data?.actionKey === 'ok') {
        this.trackActionClicks('ppt2CaseFileRemoved', 'case', 'caseCreateSelector');
        this.attachedFile = null;
      }
    })
  }

  uploadPPTDocument(): void {

    if (this.preparingPptUpload) {
      return;
    }

    this.preparingPptUpload = true;

    const uploadFile: UploadFile = {
      key: 'document',
      file: this.attachedFile.file,
      fileName: this.attachedFile.fileName,
      mimeType: this.attachedFile.mimeType
    }

    const uploadPPTDocumentResponseAndProgress = this.uploadService.uploadPPTDocument([uploadFile], this.pptFlowSource);

    const progressSubscription = uploadPPTDocumentResponseAndProgress.progress.subscribe(progress => {
      this.pptUploadProgress = progress ? progress / 100 : progress;
    });

    uploadPPTDocumentResponseAndProgress.responsePromise.then(() => {
      this.modalController.dismiss(null, 'success');
    }).catch(error => {
      this.trackFileEvent(ActionTracked.uploaded, uploadFile.fileName, uploadFile.mimeType, JSON.stringify(error));
      this.modalController.dismiss(null, 'failed');
    }).finally(() => {
      progressSubscription?.unsubscribe();
      this.pptUploadProgress = undefined;
      this.preparingPptUpload = false;
    });
  }

  trackFileEvent(action: ActionTracked, objectId: string, objectType: string, reason?: string): Promise<void> {
    const paramsToTrack: UploadMediaTrackingParam = {
      objectId,
      objectType,
      source: this.pptFlowSource,
      reason,
      uploadFailed: true
    };

    if (!reason) {
      delete paramsToTrack.uploadFailed;
    }

    const trackData: TrackingRequest = {
      action: action,
      params: paramsToTrack
    };

    return this.trackingService.track(trackData).catch(_err => {
      console.error('Something went wrong on upload action: ' + _err);
    });
  }

  trackActionClicks(objectId: string, objectType: string, objectTitle: string): void {
    this.trackingService
      .trackGenericClickedAction(objectId, objectType, objectTitle, {
        source: this.pptFlowSource,
      })
      .catch(_err => {
        console.error('Could not track content created click action.');
      });
  }

  get pptFlowSource(): string {
    return 'caseFolioPage_createCaseSelector';
  }
}
