// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --outlink-icon-size: 22px;
}

.outlink-button {
  --background: var(--mi-background-color);
  --box-shadow: none;
  --border-radius: 10px;
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  height: 63px;
}
.outlink-button .outlink-button-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.outlink-button .outlink-button-content .item-icon {
  margin-right: 6px;
  color: var(--mi-color-text-lite);
  --icon-size: var(--outlink-icon-size);
}
.outlink-button .outlink-button-content .button-text {
  text-transform: none;
  text-align: left;
  font-size: var(--font-size-regular);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.outlink-button .outlink-button-content .outlink-icon {
  color: var(--mi-black-color);
  justify-content: end;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
