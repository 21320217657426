import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { Connection } from 'src/app/services/yeti-protocol/connections';
import { PublicProfile } from 'src/app/services/yeti-protocol/public-profile';
import { ConnectionStatus } from 'src/app/services/yeti-protocol/connection';

// services
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { PublicProfileService } from 'src/app/services/public-profile.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConnectionsApiService } from 'src/app/services/messenger/connections-api.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { CONTEXT_DIALOGS_UI, ContextDialogsUI } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import { PeopleYouMayKnow } from 'src/app/state/people-you-may-know/people-you-may-know.actions';
import { Store } from '@ngxs/store';
import { ClinicalExpertsService } from '../../services/clinical-experts.service';
import { Posts } from 'src/app/state/posts/posts.actions';

@Component({
  selector: 'app-messenger-button',
  templateUrl: './messenger-button.component.html',
  styleUrls: ['./messenger-button.component.scss'],
})
export class MessengerButtonComponent implements OnInit {

  @Input() connectionStatus: ConnectionStatus;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() userId: string;
  @Input() connectionId: string;
  @Input() directConnect: boolean;

  @Output() sendMessage: EventEmitter<string> = new EventEmitter();
  userProfile: PublicProfile;
  currentUser: UserProfile;

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private publicProfileService: PublicProfileService,
    private authService: AuthService,
    private connectionsApiService: ConnectionsApiService,
    private dialogs: DialogsUIService,
    @Inject(CONTEXT_DIALOGS_UI) private contextDialogs: ContextDialogsUI,
    @Inject(UI_UTILS_SERVICE) private uiUtilsService: UIUtilsServiceInterface,
    private store: Store,
    private clinicalExpertService: ClinicalExpertsService,
  ) { }

  ngOnInit(): void {
    this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.currentUser = userProfile;
    });
  }

  get showConnectButton(): boolean {
    return this.connectionStatus === 'none';
  }

  get showMessageButton(): boolean {
    return this.connectionStatus === 'connected';
  }

  get showPendingButton(): boolean {
    return this.connectionStatus === 'pending';
  }

  getPublicUserProfile(): Promise<void> {
    if (this.userId) {
      return this.publicProfileService.getPublicProfile(this.userId).then(async (response) => {
        this.userProfile = await response.result;
      });
    }

    return Promise.reject();
  }

  connect(): void {
    if (this.directConnect) {
      this.connectAction();
    } else {
      this.showSendNewConnectionRequestDialog();
    }
  }

  async connectAction(message = ''): Promise<void> {
    this.getPublicUserProfile().then(async () => {
      if (this.userProfile.connectOnlyWithAoMembers && !this.currentUser.isAOMember) {
        await this.openBecomeAoMemberDialog();
      } else {
        this.connectionsApiService.requestConnection(this.userProfile.userId, message)
          .then((connection: Connection) => {
            this.connectionStatus = connection?.status;
            this.store.dispatch(new PeopleYouMayKnow.DisableConnectButtonForUser(this.userProfile.userId));
            this.followUser(true);
          })
          .catch(async errData => {
            if (errData === 'User is not verified') {
              return;
            }
            if (errData?.error?.error?.info === '{"connection":"Restricted to ao members"}') {
              await this.openBecomeAoMemberDialog();
            } else {
              this.dialogs.showErrorDialog(errData?.error?.error?.info);
            }
          });
      }
    });
  }

  async followUser(followUser: boolean): Promise<void> {

    if (!this.userProfile?.userId) {
      return;
    }

    this.clinicalExpertService.updateClinicalExpertFollow(
      this.userProfile.userId,
      followUser).then(async response => {
        if (response) {
          this.userProfile.isFollower = followUser;
          this.store.dispatch(new Posts.UpdatePostsOwnerFollowingStatus(this.userProfile.userId, followUser));
        }
      });
  }

  async openBecomeAoMemberDialog(): Promise<void> {
    this.uiUtilsService.showBecomeAoMemberDialog().then(accepted => {
      if (accepted) {
        this.uiUtilsService.onBecomeAOMember(this.contextService?.currentContext?.aoMembershipUrl);
      }
    });
  }

  showSendNewConnectionRequestDialog(): Promise<void> {
    return this.contextDialogs.showSendNewConnectionRequestDialog(this)
      .then(sent => {
        if (sent) {
          this.connectionStatus = 'pending';
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  message(): void {
    if (!this.connectionId) {
      return;
    }

    this.sendMessage.emit(this.connectionId);
  }
}
